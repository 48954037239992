<template>
  <div style="background-color: white;height: 100vh">
    <div class="box">
      <div
        style="position: absolute; width: 45px; height: 45px; "
        @click="slip()"
      >
        <img loading="lazy" src="../../assets/slipleft.png" />
      </div>

      <div class="ViewTitle">{{ title }}</div>
      <div class="contain gery">
        <el-form
          :label-position="labelPosition"
          label-width="100px"
          :model="form"
        >
          <el-form-item label="手机区号">
            <el-select v-model="form.areaCode" placeholder="请选择区号">
              <el-option
                v-for="(item, index) in cityCode"
                :key="index"
                :label="`${item.displayMapping.zhCN} (+${item.areaCode})`"
                :value="item.areaCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" class="codeBox">
            <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
            <el-button
              type="text"
              :disabled="disabled"
              class="setCode"
              @click="getCode()"
              >{{ setCode }}</el-button
            >
          </el-form-item>
          <div class="submit" @click="submit()">确认</div>
        </el-form>
      </div>
    </div>
    <div class="islider" v-if="show">
      <bc-slider
        @validImg="validImg"
        @close="onClose"
        :backgroupImg="backgroupImg"
        :moveImg="moveImg"
        :log="true"
      ></bc-slider>
      <!-- @getImg="getImg" -->
    </div>
  </div>
</template>
<script>
import { smsSend, captcha, captchaVerify, bindPhone } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import bcSlider from "@/components/sliderpc";
export default {
  name: "ChangePhone",
  components: { bcSlider },
  data() {
    return {
      changeVisable:1,
      title: "修改手机号",
      titleEn: "Modify mobile phone number",
      labelPosition: "right",
      form: {
        areaCode: "",
        phone: "",
        code: "",
      },
      backToken: "",
      mobile: "",
      secretKey: "",
      backgroupImg: "",
      moveImg: "",
      show: false,
      cityCode: [],
      disabled: false,
      setCode: "验证码",
      setCodeEn: "Verification code",
      languageSelect: "",
    };
  },
  computed: {},
  watch: {
    "$store.state.cityCode"(newValue) {
      this.cityCode = newValue;
    },
  },
  created() {
    this.cityCode = this.$store.state.cityCode;
    localStorage.setItem("myclick", 1);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.getImg();
    let type = this.$route.query.type;
    this.title = type == 1 ? "修改手机号" : "绑定手机号";
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    onClose() {
      this.show = false;
      this.getImg();
    },
    // 获取滑动验证码（下方有格式截图）
    getImg() {
      captcha().then((res) => {
        this.backToken = res.data.token;
        this.secretKey = res.data.secretKey;
        this.backgroupImg = res.data.originalImageBase64;
        this.moveImg = res.data.jigsawImageBase64;
      });
    },
    // 操作滑动后返回值，并传去后端验证
    validImg(moveX, fn) {
      // moveX为小滑块滑动的距离
      // 330为背景图的宽度，可设置为动态变量，310是偏移量
      const moveLeftDistance = (moveX * 310) / parseInt(330);
      const data = {
        pointJson: JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
        token: this.backToken,
        type: 1,
        phone: this.form.phone,
        areaCode: this.form.areaCode,
        // sender: this.form.phone,
      };
      captchaVerify(data)
        .then((res) => {
          const result = res;
          console.log(res.data);
          if (result.data) {
            this.onClose();
            // 调用登录的请求方法
            let data = {
              phone: this.form.phone,
              areaCode: this.form.areaCode,
              captchaToken: res.data,
              event:
                this.title == "修改手机号"
                  ? "UPDATE_MOBILE_SMS"
                  : "UPDATE_MOBILE_SMS",
            };
            smsSend(data).then((respon) => {
              if (!respon.data.data) {
                this.$message({
                  message: respon.msg,
                  type: "success",
                });
                this.tackBtn(); //验证码倒数60秒
              } else {
                // console.log(data);
                let data1 = JSON.parse(respon.data.data);
                this.$message({
                  message: data1.error_msg,
                  type: "error",
                });
              }
            });
          } else {
            fn(false);
          }
        })
        .catch(() => {
          fn(false);
        });
      this.show = true;
    },
    slip() {
      this.form = {
        areaCode: "",
        phone: "",
        code: "",
      };
      this.$router.go(-1);
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("请选择区号");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      this.show = true;
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取中...";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    submit() {
      // eslint-disable-next-line no-unreachable
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("请选择区号");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      this.form.token = localStorage.getItem("Zyaccess_token"); // 竹云token
      this.form.language = this.languageSelect;
      bindPhone(this.form).then(() => {
        this.$message({
          message: "修改成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            location.reload();
            this.getUserCom();
            this.$router.go(-1);
          },
        });
      });
    },
    getCodeEn() {
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("Please select an area code");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("Please enter your phone number");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("Please enter the correct phone number");
        return false;
      }
      this.show = true;
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn = "Try again in" + time + " secondS";
          time--;
        }
      }, 1000);
    },
    submitEn() {
      // eslint-disable-next-line no-unreachable
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("Please select an area code");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("Please enter your phone number");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("Please enter the correct phone number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      this.form.token = localStorage.getItem("Zyaccess_token"); // 竹云token
      this.form.language = this.languageSelect;
      bindPhone(this.form).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            this.getUserCom();
            this.$router.go(-1);
          },
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.gery .el-select {
  width: 100%;
  background: #f5f5f7 !important;
  border-radius: 5px;
}
.gray{

}
.box {
  background: #fff;
  .ViewTitle {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding: 0 50px;
    text-align: center;
  }
  .contain {
    padding: 25px 15px;
    width: 100vw;
    position: relative;
    .el-form-item {
      margin-bottom: 20px;
    }
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border-radius: 5px;
      margin: 30px auto 20px auto;
      background: var(--main-color);
      color: #ffffff;
      cursor: pointer;
    }
  }
  .codeBox {
    .el-form-item__content {
      width: 200px !important;
      position: relative;
    }
    .setCode {
      position: absolute;
      right:-20px;
      width: 110px;
      height: 48px;
      // line-height: 50px;
      text-align: center;
      border-radius: 4px;
      background: var(--main-color);
      color: #fff;
      font-size: 14px;
      margin-left: 15px;
    }
  }
  ::v-deep {
    .el-form-item__content {
      width: 200px;}
    .el-input__inner {
      width: 60vw;
      height: 48px;
      font-size: 14px;
      background-color: #fff;
    }

    .el-input__suffix{
      right:-20px;
    }
    .el-form-item__label {
      color: #666;
      font-size: 14px;
      padding-right: 25px;
      line-height: 48px;
    }
  }
}

::v-deep {
  .el-input__inner {
    height: 48px;

  }

  .el-form-item__label {
    color: #666;
    font-size: 14px;
    padding-right: 32px;
    line-height: 48px;
  }
  .codeBox {
    .el-form-item__content {
      .el-input {
        flex: 1;
      }
      .setCode {
        width: 75px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>

